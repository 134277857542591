<script setup lang="ts">
const userStore = useUserStore()
</script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />

    <NovaLayoutMain
      :page-mode="true"
      :mobile-flex-column="true"
      :mobile-flex-column-no-pd="true"
      class="nova-page-layout-main"
    >
      <template #left>
        <ClientOnly>
          <NovaHeaderLeftSide v-if="userStore.isSignIn" :scroll="false">
            <NovaBoxPremiumCreators />
          </NovaHeaderLeftSide>
        </ClientOnly>
      </template>

      <template #center>
        <NuxtPage />
      </template>

      <template #right>
        <NovaHeaderRightSide :scroll="true">
          <NovaBoxPremiumBanner />
        </NovaHeaderRightSide>
      </template>
    </NovaLayoutMain>
  </NovaLayoutWrapper>
</template>

<style lang="scss" scoped>
.nova-page-layout-main {
  @include mobile {
    padding-top: 72px;
  }
}
</style>
