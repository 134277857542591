<script setup lang="ts">
const etcStore = useEtcStore()
const isLoading = ref(false)
const isError = ref(false)

onBeforeMount(async () => {
  await fetchPremiumBanners()
})

// 메인배너 목록 조회
const fetchPremiumBanners = async () => {
  try {
    isLoading.value = true
    isError.value = false
    await etcStore.fetchPremiumBanners()
  } catch {
    isError.value = true
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <ClientOnly>
    <div class="premium-banner-list">
      <template v-if="!isLoading && !isError">
        <NovaBoxPremiumBannerItem
          v-for="banner in etcStore.premiumBanners"
          :key="banner.bannerSn"
          :source="banner"
        />
      </template>

      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'light'"
        class="loading-indicator"
      />

      <NovaBoxEmptyMessage
        v-if="!isLoading && isError"
        :message="$t('home.topBannerError')"
      />
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.premium-banner-list {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 100%;
}
</style>
