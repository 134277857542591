<script setup lang="ts">
import type { PremiumCreator } from '@store'
import { ROUTES } from '@configs'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const route = useRoute()
const layoutStore = useLayoutStore()
const mypageStore = useMyPageStore()
const postStore = usePostStore()
const userStore = useUserStore()
const { premiumCreators } = storeToRefs(mypageStore)
const { premiumPosts } = storeToRefs(postStore)
const topPosition = ref()

onBeforeMount(async () => {
  if (userStore.isSignIn) {
    await mypageStore.fetchPremiumCreators()
  }
  setTopPosition()
  window.addEventListener('resize', setTopPosition)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', setTopPosition)
})

const handleOnFetchPremiumPosts = async (
  creatorUserSn: PremiumCreator['crtrUserSn']
) => {
  await useNavigations({
    url: ROUTES.NOVAPLUS.path,
    query: { ...route.query, creatorUserSn },
    type: 'replace',
  })
  await postStore.fetchPremiumPosts(
    { ...postStore.premiumPosts.payload, creatorUserSn },
    true,
    premiumPosts.value.payload.creatorUserSn === creatorUserSn
  )
  await nextTick(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 300)
  })
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '노바+ > 사이드 메뉴 > 크리에이터 선택',
    eventLabel: '',
    eventSlot: '선택한 크리에이터의 게시물만 노출',
    eventI18nAddr: '',
    eventComponent: 'Button',
    creatorUserSn,
  })
}

const setTopPosition = () => {
  const responsive = useGetResponsive()
  if (responsive === 'mobile') {
    topPosition.value = {
      position: 'fixed',
      top: `${layoutStore.headerRect?.height}px`,
    }
  } else {
    topPosition.value = {
      position: 'relative',
      top: '',
    }
  }
}
</script>

<template>
  <NovaBoxBase
    :class="[
      'box-premium-creators',
      { fixed: topPosition?.position === 'fixed' },
    ]"
    :style="topPosition"
  >
    <div class="contents">
      <h4
        v-dompurify-html="
          `${t('subscribe.premiumCreators.title', {
            premiumBrand: t('subscribe.premiumBrand'),
          })}(${premiumCreators.data.length})`
        "
        class="title"
      />
    </div>

    <ClientOnly>
      <div class="premium-creators">
        <NovaLoadingIndicator
          v-if="premiumCreators.loading"
          :size="80"
          :fill="true"
          :bg-bright="'light'"
        />

        <template
          v-if="
            !premiumCreators.loading &&
            !premiumCreators.error &&
            premiumCreators.data.length
          "
        >
          <NovaBoxPremiumCreatorsItem
            v-for="creator in premiumCreators.data"
            :key="creator.crtrUserSn"
            :on="creator.crtrUserSn === premiumPosts.payload.creatorUserSn"
            :source="creator"
            @click="() => handleOnFetchPremiumPosts(creator.crtrUserSn)"
          />
        </template>

        <NovaBoxEmptyMessage
          v-if="
            !premiumCreators.loading &&
            !premiumCreators.error &&
            !premiumCreators.data.length
          "
          :theme="'illustration'"
          :message="
            t('subscribe.premiumCreators.empty', {
              subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
            })
          "
          :fill="true"
          class="message-box"
        />

        <NovaBoxEmptyMessage
          v-if="!premiumCreators.loading && premiumCreators.error"
          :theme="'illustration'"
          :message="
            t('subscribe.premiumCreators.error', {
              subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
            })
          "
          :fill="true"
          class="message-box"
        />
      </div>
    </ClientOnly>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.box-premium-creators {
  flex-direction: column;
  gap: 16px !important;
  width: 100%;
  max-height: 100%;
  padding: 0 !important;

  &.fixed {
    z-index: $z-index-scroll-top-button;
  }

  > .contents {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 20px 15px 0;

    > .title {
      @include text-style($text-display-bold);
      color: $color-text-2;
    }
  }

  > .premium-creators {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px 20px;
    overflow-y: overlay;

    .message-box {
      :deep(.message) {
        color: $color-black !important;
      }
    }
  }

  @include mobile {
    border-radius: 0 0 5px 5px !important;
    box-shadow: 0 2px 3px 0 hex-to-rgba($color-black, 0.25);

    > .contents {
      display: none;
    }

    > .premium-creators {
      flex-direction: row;
      gap: 0;
      padding: 0 13px;
      overflow-y: hidden;
      overflow-x: overlay;
      border-top: 1px solid $color-black-5p;
      //overflow: visible;

      .message-box {
        min-height: 0 !important;
        flex-direction: row !important;
        height: 74px !important;

        :deep(img) {
          width: 40px;
        }

        :deep(.message) {
          text-align: left;
        }
      }
    }
  }
}
</style>
